<template>
    <div>
        <div class="my-2 mb-6">
            <v-btn
                color="primary"
                dark
                large
                @click="$router.push({ name: 'NewArticle'})"
            >
                Crear Nueva Noticia
            </v-btn>
        </div>

        <v-row>
            <v-col
                v-for="(article, index) in articles"
                :key="index"
                cols="12"
                sm="4"
            >
                <v-card class="card_article">
                    <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="article.thumbnail"
                    >
                        <v-card-title>{{ article.title }}</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">
                        {{ article.publication_date }}
                    </v-card-subtitle>

                    <v-card-text class="text--primary card_content" >
                        <div v-html="article.description"></div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            @click="deleteItem(article.id)"
                        >
                            Eliminar
                        </v-btn>

                        <v-btn
                            color="primary"
                            text
                            @click="$router.push({ name: 'NewArticle', params: {id: article.id} });"
                        >
                            Editar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
    

    
    
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data(){
        return{
            articles: [],
            loader: true
        }
    },
    mixins: [misMixins],
    methods:{
        getArticles(){
            this.$http.get('articles/townhall/'+this.getClientSelect().id)
            .then(response => {
                this.articles = response.body;
                console.log(this.articles)
            }).catch(error => {
                console.log('Error al consultar las dimensiones', error)
            })

            this.loader = false;
        },
        deleteItem(id){

            this.$swal.fire({
                title: 'Está seguro de Eliminar esta noticia?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: `Cancelar`,
                icon: 'warning',
                }).then((result) => {
                
                if (result.isConfirmed) {

                    this.LoadingOpen();

                    this.$http.delete('articles/'+ id,
                    {
                        headers:{"Authorization": 'Bearer '+this.getCrypto()
                    }
                    }).then(response => {
                            this.getArticles();
                            this.LoadingClose();

                            this.SucessAlert(
                                'Eliminado',
                                'noticia eliminada con éxito'
                            )
                    }).catch(error => {
                        this.LoadingClose();
                    
                        this.ErrorAlert('Error al eliminar la noticia')
                    })

                } 
                })


        },
    },
    created() {
        this.getArticles();
    },
}
</script>
<style lang="scss" >
    .card_article .card_content{
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 20;
        height: 190px;
    }
    .card_article .v-responsive__sizer{
        background-color: #2727278f !important;
    }
</style>